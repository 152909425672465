import React from 'react'
import IndexPageTemplate from '../../components/pages/indexTemplate/indexTemplate'
import { graphql } from 'gatsby'

function IndexPageSk({ data }) {
  return <IndexPageTemplate data={data} lang={'sk'} videoSrc={'/videos/Vid-4-1.mp4'}/>
}

export const query = graphql`{
    landingScreen: file(relativePath: {eq: "pages/sk/homepage/landing_screen.md"}) {
        childMarkdownRemark {
            html
            frontmatter {
                title
                subtitle
                background_image {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                featured_post {
                    title
                    link
                    is_new
                }
            }
        }
    }
    featuredLogos: file(relativePath: {eq: "pages/sk/homepage/featured_logos.md"}) {
        childMarkdownRemark {
            html
            frontmatter {
                logos {
                    href {
                        childImageSharp {
                            fixed(height: 150) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    alt
                }
            }
        }
    }
    caseStudies: file(relativePath: {eq: "pages/sk/homepage/featured_case_studies.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    background_color
                    case_study
                    description
                    category
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    saidAboutUs: file(relativePath: {eq: "pages/sk/homepage/said_about_us.md"}) {
        childMarkdownRemark {
            frontmatter {
                text_paragraph
                references {
                    description
                     logo: logo {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    logoBig: logo {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    author
                }
            }
        }
    }
    featuredBlogPosts: file(relativePath: {eq: "pages/sk/homepage/featured_blog_posts.md"}) {
        childMarkdownRemark {
            frontmatter {
                blog_posts {
                    title
                    description
                    image {
                        childImageSharp {
                            fixed(width: 500, height: 500) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    category
                    blog_post
                }
            }
        }
    }
    contact: file(relativePath: {eq: "pages/sk/homepage/contact.md"}) {
        childMarkdownRemark {
            frontmatter {
                title
                text_paragraph
            }
        }
    }
}
`

export default IndexPageSk
